<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="字典编码">
            <el-input placeholder="请输入" v-model="form.dictKey" clearable></el-input>
          </el-form-item>
          <el-form-item label="字典值">
            <el-input placeholder="请输入" v-model="form.dictValue" clearable></el-input>
          </el-form-item>
          <el-form-item label="租户ID" v-if="form.globalOrLocal == 'local' && !dictKey">
            <el-select v-model="form.tenantId" filterable clearable allow-create>
              <el-option
                v-for="(item, index) in tenantList"
                :key="index"
                :label="item.tenantCode"
                :value="item.tenantCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button type="primary" plain @click="handleAdd">新增</el-button>
          </el-form-item>
          <el-form-item v-if="dictKey">
            <el-tag type="info">上级字典编码：{{ dictKey }}</el-tag>
          </el-form-item>
        </el-form>
        <!-- <el-tabs v-model="form.globalOrLocal" type="card" @tab-click="query" v-if="!dictKey">
          <el-tab-pane label="全局" name="global"></el-tab-pane>
          <el-tab-pane label="租户" name="local"></el-tab-pane>
        </el-tabs>-->
      </template>
      <template #default>
        <el-table
          ref="table"
          :data="tableData"
          v-loading="tableLoading"
          border
          stripe
          height="100%"
        >
          <el-table-column prop="dictKey" label="字典编码" align="center" min-width="150px">
            <template slot-scope="scope">
              <span class="link cursor" @click="handleDetail(scope.row)">
                {{
                scope.row.dictKey
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="dictValue" label="字典值" align="center" min-width="150px" />
          <el-table-column prop="status" label="字典状态" align="center" min-width="150px">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.status == 'enable'
                    ? 'enable-status'
                    : 'disable-status'
                "
              >{{ scope.row.status == 'enable' ? '启用' : '禁用' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dictLevel" label="字典级别" align="center" min-width="150px">
            <template slot-scope="scope">
              <span>{{ scope.row.dictLevel == 1 ? '子级' : '父级' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center" min-width="150px" />
          <el-table-column prop="createTime" label="创建时间" align="center" min-width="150px" />
          <el-table-column label="操作" align="center" min-width="150px" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" @click="handleEditStatus(scope.row)">
                {{
                scope.row.status == 'enable' ? '禁用' : '启用'
                }}
              </el-button>
              <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination
          class="mt10"
          :page-size="page.size"
          :current-page="page.current"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </template>
    </common-layout>
    <add-modal :show.sync="showAdd" :type="formType" :detail="curRow" @close="closeModal" />
  </div>
</template>
<script>
import api from '@/api/devops/baseConfig'
import addModal from './addModal'
export default {
  components: { addModal },
  data() {
    return {
      form: {
        globalOrLocal: 'global',
      },
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      showAdd: false,
      curRow: null,
      formType: 'add',
      tenantList: [],
    }
  },
  computed: {
    dictKey() {
      return this.$route.path == '/devops/baseConfig'
        ? ''
        : this.$route.query.dictKey
    },
    globalOrLocal() {
      return this.$route.query.globalOrLocal
    },
    tenantId() {
      return this.$route.query.tenantId
    },
  },
  watch: {
    dictKey() {
      this.query()
    },
  },
  mounted() {
    this.query()
    this.getTentantList()
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
        tenantId: 'back_end',
      }
      // if (params.globalOrLocal == 'global') {
      //   delete params.tenantId
      // }
      if (this.dictKey) {
        params.parentKey = this.dictKey
        params.globalOrLocal = this.globalOrLocal
        params.tenantId = this.tenantId
      }
      this.tableLoading = true
      api
        .getList(params)
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleQuery() {
      this.page.current = 1
      this.query()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
    handleAdd() {
      this.formType = 'add'
      this.showAdd = true
    },
    handleEdit(row) {
      this.formType = 'edit'
      this.curRow = _.cloneDeep(row)
      this.showAdd = true
    },
    closeModal() {
      this.showAdd = false
      this.query()
    },
    handleEditStatus(row) {
      this.$confirm(
        `确定${row.status == 'enable' ? '禁用' : '启用'}此数据吗？`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        let params = _.cloneDeep(row)
        if (params.status === 'enable') {
          params.status = 'unable'
        } else {
          params.status = 'enable'
        }
        api.updateStatus(params).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        api.delete({ id: row.id }).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
    handleDetail(row) {
      if (this.dictKey) {
        return
      }

      this.page.current = 1
      this.form.dictKey = null
      this.form.dictValue = null
      this.$router.push({
        path: '/devops/baseConfigDetail',
        query: {
          dictKey: row.dictKey,
          globalOrLocal: row.globalOrLocal,
          tenantId: row.tenantId,
        },
      })
    },
    getTentantList() {
      api
        .getTentantList({
          current: 1,
          size: 999,
        })
        .then(({ data }) => {
          this.tenantList = data.records
        })
    },
  },
}
</script>

