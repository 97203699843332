import http from '@/utils/axios'
const api = {
  getList: (data) => http.post(`/cmp-console/dict/pageBackEnd`, data),
  add: (data) => http.post(`/cmp-console/dict/createBackEnd`, data),
  update: (data) => http.post(`/cmp-console/dict/updateBackEnd`, data),
  updateStatus: (data) => http.post(`/cmp-console/dict/updateStatus`, data),
  delete: (data) => http.post(`/cmp-console/dict/remove`, data),
  getTentantList: (data) => http.get('/tenant-user/enterpriseAccount/back_end/query', data),
}
export default api
