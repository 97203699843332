<template>
  <el-dialog :title="type == 'add' ? '新增字典' : '编辑字典'" width="500px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form label-width="80px" :model="form" :rules="rules" ref="form">
      <el-form-item label="字典编码" prop="dictKey">
        <el-input placeholder="请输入" v-model="form.dictKey" clearable :disabled="type == 'edit'"></el-input>
      </el-form-item>
      <el-form-item label="字典值" prop="dictValue">
        <el-input placeholder="请输入" v-model="form.dictValue" clearable></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input placeholder="请输入" v-model="form.sort" type="number" clearable></el-input>
      </el-form-item>
      <!-- <el-form-item label="字典级别" prop="globalOrLocal">
        <el-radio-group v-model="form.globalOrLocal" :disabled="!!dictKey" @change="changeLevel">
          <el-radio label="global">全局</el-radio>
          <el-radio label="local">租户</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="字典状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio label="enable">启用</el-radio>
          <el-radio label="unable">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="租户ID" prop="tenantId">
        <el-select v-model="form.tenantId" style="width: 100%" filterable clearable allow-create :disabled="!!dictKey || form.globalOrLocal == 'global'">
          <el-option v-for="(item, index) in tenantList" :key="index" :label="item.tenantCode" :value="item.tenantCode"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input placeholder="请输入" v-model="form.remark" type="textarea" clearable></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/devops/baseConfig'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
    type: {
      default: () => 'add',
      type: String,
    },
  },
  data() {
    return {
      form: {},
      rules: {
        dictKey: [{ required: true, message: '请输入字典编码', trigger: 'blur' }],
        dictValue: [{ required: true, message: '请输入字典值', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        tenantId: [{ required: true, message: '请选择租户', trigger: 'blur' }],
      },
      tenantList: [],
      tempTenantId: '',
    }
  },
  computed: {
    dictKey() {
      return this.$route.path == '/devops/baseConfig' ? '' : this.$route.query.dictKey
    },
    globalOrLocal() {
      return this.$route.query.globalOrLocal
    },
    tenantId() {
      return this.$route.query.tenantId
    },
  },
  watch: {
    show(val) {
      if (val) {
        if (this.type == 'add') {
          this.form = {
            status: 'enable',
            globalOrLocal: 'global',
            tenantId: 'back_end',
          }
        } else {
          this.form = { ...this.detail }
        }
        if (this.dictKey) {
          this.$set(this.form, 'globalOrLocal', this.globalOrLocal)
          this.$set(this.form, 'tenantId', this.tenantId)
        }
      } else {
        this.$refs['form'].resetFields()
      }
    },
  },
  created() {
    this.getTentantList()
  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    add() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        let params = {
          ...this.form,
          parentKey: this.dictKey || '',
          dictLevel: this.dictKey ? 1 : 0,
        }
        const fn = this.type == 'add' ? api.add : api.update
        fn(params).then(() => {
          this.$msg.success('操作成功')
          this.$emit('close')
        })
      })
    },
    getTentantList() {
      api
        .getTentantList({
          current: 1,
          size: 999,
        })
        .then(({ data }) => {
          this.tenantList = data.records
        })
    },
    changeLevel(val) {
      if (val == 'global') {
        this.tempTenantId = this.form.tenantId
        this.$set(this.form, 'tenantId', 'back_end')
      } else {
        this.$set(this.form, 'tenantId', this.tempTenantId)
      }
    },
  },
}
</script>
